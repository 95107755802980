import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/components/last-position.scss";

export default function LastPosition() {
  const { t } = useTranslation();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [showMore, setShowMore] = useState(false);

  const handleChangeShowMore = () => {
    setShowMore((current) => !current);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  return (
    <div className="last-position spacing-block">
      <div className="content">
        <h2 className="text-6xl">{t("last_position.title")}</h2>
        <p className="text-2xl">{t("last_position.text")}</p>
        <div className="buttons">
          <Link
            to="/cv"
            target="_blank"
            className="text-xl animated-cursor"
          >
            {t("last_position.button_resume")}
          </Link>
          <Link
            to={"https://www.linkedin.com/in/benjamin-arpage"}
            className="text-xl animated-cursor"
            target="_blank"
          >
            {t("last_position.button_linkedin")}
          </Link>
        </div>
      </div>
      <div className="jobs">
        <div className="job">
          <div className="head">
            <h3 className="text-3xl">Aplim</h3>
          </div>
          <div className="discover-more-wrapper">
            <div className="date text-xl">{t("last_position.since")}{" "}10/24</div>
            <div className="location text-xl">Angers, France</div>
            <div className="job-title text-2xl">
              {t("last_position.aplim")}
            </div>
          </div>
        </div>
        <div className="job">
          <div className="head">
            <h3 className="text-3xl">Locala</h3>
          </div>
          <div className="discover-more-wrapper">
            <div className="date text-xl">01/23 — 04/24 </div>
            <div className="location text-xl">Paris, France</div>
            <div className="job-title text-2xl">
              {t("last_position.locala")}
            </div>
          </div>
        </div>
        <div className="job">
          <div className="head">
            <h3 className="text-3xl">Lengow</h3>
          </div>
          <div className="discover-more-wrapper">
            <div className="date text-xl">07/21 — 01/23</div>
            <div className="location text-xl">Nantes, France</div>
            <div className="job-title text-2xl">
              {t("last_position.lengow")}
            </div>
          </div>
        </div>
        <div className="job">
          <div className="head">
            <h3 className="text-3xl">Edflex</h3>
          </div>
          <div className="discover-more-wrapper">
            <div className="date text-xl">09/19 — 07/21</div>
            <div className="location text-xl">Nantes, France</div>
            <div className="job-title text-2xl">
              {t("last_position.edflex")}
            </div>
          </div>
        </div>
        {showMore ? (
          <>
            <div className="job">
              <div className="head">
                <h3 className="text-3xl">noeup'app</h3>
              </div>

              <div className="discover-more-wrapper">
                <div className="date text-xl">03/19 — 09/19</div>
                <div className="location text-xl">Nantes, France</div>
                <div className="job-title text-2xl">
                  {t("last_position.noeupapp")}
                </div>
              </div>
            </div>
            <div className="job">
              <div className="head">
                <h3 className="text-3xl">Naoned</h3>
              </div>
              <div className="discover-more-wrapper">
                <div className="date text-xl">04/18 — 08/18</div>
                <div className="location text-xl">Nantes, France</div>
                <div className="job-title text-2xl">
                  {t("last_position.naoned")}
                </div>
              </div>
            </div>
            <div className="job">
              <div className="head">
                <h3 className="text-3xl">EXPLORE</h3>
              </div>
              <div className="discover-more-wrapper">
                <div className="date text-xl">05/17 — 08/17</div>
                <div className="location text-xl">Nantes, France</div>
                <div className="job-title text-2xl">
                  {t("last_position.explore")}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {windowSize.innerWidth <= 768 ? (
          <></>
        ) : (
          <div
            className="show-more text-3xl animated-cursor"
            onClick={handleChangeShowMore}
          >
            {!showMore ? <p>{t("last_position.show_more")}</p> : <p>{t("last_position.show_less")}</p>}
          </div>
        )}
      </div>
      {windowSize.innerWidth > 769 ? (
        <></>
      ) : (
        <div
          className="show-more text-3xl animated-cursor"
          onClick={handleChangeShowMore}
        >
          {!showMore ? <p>{t("last_position.show_more")}</p> : <p>{t("last_position.show_less")}</p>}
        </div>
      )}
    </div>
  );
}
